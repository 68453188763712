
// Email obfuscator script 2.1 by Tim Williams, University of Arizona
// Random encryption key feature coded by Andrew Moulden
// This code is freeware provided these four comment lines remain intact
// A wizard to generate this code is at http://www.jottings.com/obfuscator/

const coded = "5Icx@ALewIoL-dLJMqwOyLboJIe.Yw"
const key = "thsVbDLW2mqiE0j8NQuRHwZgcC6SnrodM7GBl4aAT5vp31XkJFzy9ePUfKIOxY"

export class EmailObfuscator {

    public static email = (text?: string) => {
        var shift = coded.length
        var link = ""

        for (let i = 0; i < coded.length; i++) {
            let ltr: string|number
            if (key.indexOf(coded.charAt(i)) === -1) {
                ltr = coded.charAt(i)
                link += (ltr)
            } else {
                ltr = (key.indexOf(coded.charAt(i)) - shift + key.length) % key.length
                link += (key.charAt(ltr))
            }
        }
        return text == null ? "<a href='mailto:"+link+"'>"+link+"</a>" : "<a href='mailto:"+link+"'>"+text+"</a>"
    }
}